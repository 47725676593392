import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import scene_en from "../data/en/Mazes.json";
import scene_vi from "../data/vi/Mazes.json";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";

function HandleScene() {
  const [selectedOption, setSelectedOption] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const sceneData = currentLanguage === 'vi' ? scene_vi : scene_en;

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("scene")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("scene-name")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedOption}
              onChange={handleSelectChange}
              options={sceneData.map((scene) => ({
                value: scene.id,
                label: scene.name,
              }))}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            {selectedOption && (
              <input
                type="text"
                value={`/scene ${selectedOption.value}`}
                readOnly
                ref={inputRef3}
                className="command"
                onClick={handleInputClick3}
                onFocus={handleFocusAndClick}
              />
            )}
          </div>
          <div className="col-sm-5" />
        </div>
      </div>
      {showNotification && (
        <div className="notification">{t("copySuccess")}</div>
      )}
      <div style={{marginTop: '100px'}}/>
    </>
  );
}

export default HandleScene;
