import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";
import subAffix_vi from "../data/vi/subAffix.json";
import subAffix_en from "../data/en/subAffix.json";
import options_en from "../data/en/Relics.json";
import options_vi from "../data/vi/Relics.json";
import { Modal, Button, Radio, Col, Row } from "antd";

function HandleRelics() {
  const [showNotification, setShowNotification] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [subOptions, setSubOptions] = useState([]);
  const [selectedMainStat, setSelectedMainStat] = useState(null);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const optionsData = currentLanguage === "vi" ? options_vi : options_en;
  const subAffixData = currentLanguage === "vi" ? subAffix_vi : subAffix_en;
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [level2, setLevel2] = useState(1);
  const [level3, setLevel3] = useState(1);
  const [level4, setLevel4] = useState(1);
  const [level5, setLevel5] = useState(1);
  const [step2, setStep2] = useState(1);
  const [step3, setStep3] = useState(1);
  const [step4, setStep4] = useState(1);
  const [step5, setStep5] = useState(1);
  const [selectedCommand, setSelectedCommand] = useState("Give");
  const [isStepEnabled, setIsStepEnabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayValueSubAffix2, setDisplayValueSubAffix2] = useState("");
  const [displayValueSubAffix3, setDisplayValueSubAffix3] = useState("");
  const [displayValueSubAffix4, setDisplayValueSubAffix4] = useState("");
  const [displayValueSubAffix5, setDisplayValueSubAffix5] = useState("");

  const uniqueGroups = Array.from(
    new Set(optionsData.map((option) => option.group))
  ).map((group) => ({
    value: group,
    label: group,
  }));

  const filteredRelics = optionsData.filter(
    (option) => option.group === selectedGroup?.value
  );

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  const handleGroupChange = (selectedGroup) => {
    setSelectedGroup(selectedGroup);
    setSelectedPiece(null);
    setSelectedMainStat(null);
    setSubOptions([]); // Reset subOptions khi thay đổi nhóm
  };

  const handlePieceChange = (selectedPiece) => {
    setSelectedPiece(selectedPiece);
    updateSubOptions(selectedPiece); // Cập nhật các subOptions dựa trên relic piece đã chọn
    setSelectedMainStat(null); // Reset main stat khi chọn relic piece mới
  };

  const handleMainStatChange = (selectedMainStat) => {
    setSelectedMainStat(selectedMainStat);
  };

  const updateSubOptions = (selectedPiece) => {
    if (!selectedPiece) return;
    const type = selectedPiece.type;
    switch (type) {
      case "Nón":
        setSubOptions([{ label: t("hp"), value: "1", lvl_value: "705" }]);
        break;
      case "Tay":
        setSubOptions([{ label: t("atk"), value: "1", lvl_value: "352" }]);
        break;
      case "Áo":
        setSubOptions([
          { label: t("hp-%"), value: "1", lvl_value: "43.2%" },
          { label: t("atk-%"), value: "2", lvl_value: "43.2%" },
          { label: t("def-%"), value: "3", lvl_value: "54%" },
          { label: t("crit-rate"), value: "4", lvl_value: "32.4%" },
          { label: t("crit-dmg"), value: "5", lvl_value: "64.8%" },
          {
            label: t("outgoing-healing-boost"),
            value: "6",
            lvl_value: "34.5%",
          },
          { label: t("effect-hit-rate"), value: "7", lvl_value: "43.2%" },
        ]);
        break;
      case "Giày":
        setSubOptions([
          { label: t("hp-%"), value: "1", lvl_value: "43.2%" },
          { label: t("atk-%"), value: "2", lvl_value: "43.2%" },
          { label: t("def-%"), value: "3", lvl_value: "54%" },
          { label: t("speed"), value: "4", lvl_value: "25" },
        ]);
        break;
      case "Cầu":
        setSubOptions([
          { label: t("hp-%"), value: "1", lvl_value: "43.2%" },
          { label: t("atk-%"), value: "2", lvl_value: "43.2%" },
          { label: t("def-%"), value: "3", lvl_value: "54%" },
          { label: t("physical-dmg-boost"), value: "4", lvl_value: "38.8%" },
          { label: t("fire-dmg-boost"), value: "5", lvl_value: "38.8%" },
          { label: t("ice-dmg-boost"), value: "6", lvl_value: "38.8%" },
          { label: t("lightning-dmg-boost"), value: "7", lvl_value: "38.8%" },
          { label: t("wind-dmg-boost"), value: "8", lvl_value: "38.8%" },
          { label: t("quantum-dmg-boost"), value: "9", lvl_value: "38.8%" },
          { label: t("imaginary-dmg-boost"), value: "10", lvl_value: "38.8%" },
        ]);
        break;
      case "Dây":
        setSubOptions([
          { label: t("break-effect"), value: "1", lvl_value: "43.2%" },
          {
            label: t("energy-regeneration-rate"),
            value: "2",
            lvl_value: "43.2%",
          },
          { label: t("hp-%"), value: "3", lvl_value: "54%" },
          { label: t("atk-%"), value: "4", lvl_value: "64.8%" },
          { label: t("def-%"), value: "5", lvl_value: "19.4%" },
        ]);
        break;
      default:
        setSubOptions([]);
    }
  };

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedCommand(e.target.value);
    setIsStepEnabled(e.target.value === "Relics");
  };

  const handleSelectChange2 = (selectedOption2) => {
    setSelectedOption2(selectedOption2);
  };

  const handleSelectChange3 = (selectedOption3) => {
    setSelectedOption3(selectedOption3);
  };

  const handleSelectChange4 = (selectedOption4) => {
    setSelectedOption4(selectedOption4);
  };

  const handleSelectChange5 = (selectedOption5) => {
    setSelectedOption5(selectedOption5);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLevelChange2 = (value2) => {
    let newValue2 = parseInt(value2);
    if (isNaN(newValue2) || newValue2 < 1) {
      newValue2 = 1;
    }
    setLevel2(newValue2);
  };

  const handleLevelChange3 = (value3) => {
    let newValue3 = parseInt(value3);
    if (isNaN(newValue3) || newValue3 < 1) {
      newValue3 = 1;
    }
    setLevel3(newValue3);
  };

  const handleLevelChange4 = (value4) => {
    let newValue4 = parseInt(value4);
    if (isNaN(newValue4) || newValue4 < 1) {
      newValue4 = 1;
    }
    setLevel4(newValue4);
  };

  const handleLevelChange5 = (value5) => {
    let newValue5 = parseInt(value5);
    if (isNaN(newValue5) || newValue5 < 1) {
      newValue5 = 1;
    }
    setLevel5(newValue5);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("relics")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>

      <br />

      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("relics-set")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedGroup}
              onChange={handleGroupChange}
              options={uniqueGroups}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>

      <br />

      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("relics-piece")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedPiece}
              onChange={handlePieceChange}
              options={filteredRelics.map((option) => ({
                value: option.id,
                label: option.name,
                type: option.type,
              }))}
              isDisabled={!selectedGroup}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>

      <br />

      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("main-stat")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedMainStat}
              onChange={handleMainStatChange}
              options={subOptions}
              isDisabled={!selectedPiece}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>

      <br />

      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-4">
            <h5 className="name-of-selection">{t("sub-stat")}</h5>
            <Select
              value={selectedOption2}
              onChange={handleSelectChange2}
              options={subAffixData.map((affix) => ({
                value: affix.id,
                label: affix.name,
                step0: affix.step0,
                step1: affix.step1,
                step2: affix.step2,
              }))}
            />
            <Select
              value={selectedOption3}
              onChange={handleSelectChange3}
              options={subAffixData.map((affix) => ({
                value: affix.id,
                label: affix.name,
                step0: affix.step0,
                step1: affix.step1,
                step2: affix.step2,
              }))}
            />
            <Select
              value={selectedOption4}
              onChange={handleSelectChange4}
              options={subAffixData.map((affix) => ({
                value: affix.id,
                label: affix.name,
                step0: affix.step0,
                step1: affix.step1,
                step2: affix.step2,
              }))}
            />
            <Select
              value={selectedOption5}
              onChange={handleSelectChange5}
              options={subAffixData.map((affix) => ({
                value: affix.id,
                label: affix.name,
                step0: affix.step0,
                step1: affix.step1,
                step2: affix.step2,
              }))}
            />
          </div>
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("count")}</h5>
            <input
              type="number"
              value={level2}
              onChange={(e) => handleLevelChange2(e.target.value)}
              className="relics-input"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
            <input
              type="number"
              value={level3}
              onChange={(e) => handleLevelChange3(e.target.value)}
              className="relics-input"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
            <input
              type="number"
              value={level4}
              onChange={(e) => handleLevelChange4(e.target.value)}
              className="relics-input"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
            <input
              type="number"
              value={level5}
              onChange={(e) => handleLevelChange5(e.target.value)}
              className="relics-input"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-2">
            {isStepEnabled && (
              <h5 className="name-of-selection">{t("value")}</h5>
            )}

            <Row>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption2 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep2(0);
                      // Cập nhật giá trị của button vào state
                      const { value, step0 } = selectedOption2;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level2 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step0 * level2);
                      } else {
                        newValue = step0 * level2;
                      }
                      setDisplayValueSubAffix2(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step0 } = selectedOption2;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level2 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step0 * level2);
                      } else {
                        return step0 * level2;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption2 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep2(level2);
                      // Cập nhật giá trị của button vào state
                      const { value, step1 } = selectedOption2;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level2 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step1 * level2);
                      } else {
                        newValue = step1 * level2;
                      }
                      setDisplayValueSubAffix2(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step1 } = selectedOption2;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level2 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step1 * level2);
                      } else {
                        return step1 * level2;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption2 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep2(level2 * 2);
                      // Cập nhật giá trị của button vào state
                      const { value, step2 } = selectedOption2;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level2 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step2 * level2);
                      } else {
                        newValue = step2 * level2;
                      }
                      setDisplayValueSubAffix2(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step2 } = selectedOption2;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level2 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step2 * level2);
                      } else {
                        return step2 * level2;
                      }
                    })()}
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "8px" }}>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption3 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep3(0);
                      // Cập nhật giá trị của button vào state
                      const { value, step0 } = selectedOption3;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level3 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step0 * level3);
                      } else {
                        newValue = step0 * level3;
                      }
                      setDisplayValueSubAffix3(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step0 } = selectedOption3;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level3 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step0 * level3);
                      } else {
                        return step0 * level3;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption3 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep3(level3);
                      // Cập nhật giá trị của button vào state
                      const { value, step1 } = selectedOption3;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level3 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step1 * level3);
                      } else {
                        newValue = step1 * level3;
                      }
                      setDisplayValueSubAffix3(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step1 } = selectedOption3;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level3 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step1 * level3);
                      } else {
                        return step1 * level3;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption3 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep3(level3 * 2);
                      // Cập nhật giá trị của button vào state
                      const { value, step2 } = selectedOption3;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level3 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step2 * level3);
                      } else {
                        newValue = step2 * level3;
                      }
                      setDisplayValueSubAffix3(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step2 } = selectedOption3;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level3 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step2 * level3);
                      } else {
                        return step2 * level3;
                      }
                    })()}
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "8px" }}>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption4 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep4(0);
                      // Cập nhật giá trị của button vào state
                      const { value, step0 } = selectedOption4;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level4 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step0 * level4);
                      } else {
                        newValue = step0 * level4;
                      }
                      setDisplayValueSubAffix4(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step0 } = selectedOption4;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level4 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step0 * level4);
                      } else {
                        return step0 * level4;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption4 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep4(level4);
                      // Cập nhật giá trị của button vào state
                      const { value, step1 } = selectedOption4;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level4 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step1 * level4);
                      } else {
                        newValue = step1 * level4;
                      }
                      setDisplayValueSubAffix4(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step1 } = selectedOption4;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level4 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step1 * level4);
                      } else {
                        return step1 * level4;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption4 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep4(level4 * 2);
                      // Cập nhật giá trị của button vào state
                      const { value, step2 } = selectedOption4;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level4 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step2 * level4);
                      } else {
                        newValue = step2 * level4;
                      }
                      setDisplayValueSubAffix4(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step2 } = selectedOption4;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level4 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step2 * level4);
                      } else {
                        return step2 * level4;
                      }
                    })()}
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "8px" }}>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption5 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep5(0);
                      // Cập nhật giá trị của button vào state
                      const { value, step0 } = selectedOption5;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level5 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step0 * level5);
                      } else {
                        newValue = step0 * level5;
                      }
                      setDisplayValueSubAffix5(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step0 } = selectedOption5;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step0 * level5 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step0 * level5);
                      } else {
                        return step0 * level5;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption5 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep5(level5);
                      // Cập nhật giá trị của button vào state
                      const { value, step1 } = selectedOption5;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level5 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step1 * level5);
                      } else {
                        newValue = step1 * level5;
                      }
                      setDisplayValueSubAffix5(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step1 } = selectedOption5;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step1 * level5 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step1 * level5);
                      } else {
                        return step1 * level5;
                      }
                    })()}
                  </Button>
                )}
              </Col>
              <Col className="col-sm-4 d-flex justify-content-center align-items-center">
                {isStepEnabled && selectedOption5 && (
                  <Button
                    style={{ width: "90%" }}
                    type="default"
                    onClick={() => {
                      setStep5(level5 * 2);
                      // Cập nhật giá trị của button vào state
                      const { value, step2 } = selectedOption5;
                      let newValue;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level5 * 10) / 10;
                        newValue = `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        newValue = Math.round(step2 * level5);
                      } else {
                        newValue = step2 * level5;
                      }
                      setDisplayValueSubAffix5(newValue);
                    }}
                    disabled={!isStepEnabled}
                  >
                    {(() => {
                      const { value, step2 } = selectedOption5;
                      if (
                        ["12", "11", "10", "9", "8", "4", "5", "6"].includes(
                          value
                        )
                      ) {
                        const roundedValue =
                          Math.round(step2 * level5 * 10) / 10;
                        return `${roundedValue.toFixed(1)}%`;
                      } else if (["1", "2", "3", "7"].includes(value)) {
                        return Math.round(step2 * level5);
                      } else {
                        return step2 * level5;
                      }
                    })()}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="col-sm-3" />
        </div>
      </div>

      <br />

      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
            <br />
            <Radio.Group onChange={handleRadioChange} value={selectedCommand}>
              <Radio value="Give">
                <h5 className="name-of-selection">{t("give-command")}</h5>
              </Radio>
              <Radio value="Relics">
                <h5 className="name-of-selection">{t("relics-command")}</h5>
              </Radio>
            </Radio.Group>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-7">
            {selectedCommand === "Relics" &&
              selectedPiece &&
              selectedMainStat && (
                <div
                  className="outdated-message"
                  dangerouslySetInnerHTML={{ __html: t("relics-command-note") }}
                />
              )}
          </div>
          <div className="col-sm-4" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            {selectedPiece &&
              selectedMainStat &&
              selectedOption2 &&
              selectedOption3 &&
              selectedOption4 &&
              selectedOption5 && (
                <input
                  type="text"
                  value={
                    selectedCommand === "Give"
                      ? `/give ${selectedPiece.value} s${selectedMainStat.value} lv15 ${selectedOption2.value}:${level2} ${selectedOption3.value}:${level3} ${selectedOption4.value}:${level4} ${selectedOption5.value}:${level5}`
                      : `/relics ${selectedPiece.value} s${selectedMainStat.value} lv15 ${selectedOption2.value}:${level2}:${step2} ${selectedOption3.value}:${level3}:${step3} ${selectedOption4.value}:${level4}:${step4} ${selectedOption5.value}:${level5}:${step5}`
                  }
                  readOnly
                  ref={inputRef3}
                  className="command"
                  onClick={handleInputClick3}
                />
              )}
            {showNotification && (
              <div className="notification">{t("copySuccess")}</div>
            )}

            {selectedCommand === "Relics" &&
              selectedPiece &&
              selectedMainStat &&
              selectedOption2 &&
              selectedOption3 &&
              selectedOption4 &&
              selectedOption5 && (
                <Modal
                  title={selectedPiece.label}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel} // Đóng modal khi người dùng ấn vào "X" hoặc vùng ngoài modal
                  maskClosable={true} // Cho phép đóng modal khi click ra ngoài
                  footer={[
                    <Button key="ok" type="primary" onClick={handleOk}>
                      OK
                    </Button>,
                  ]}
                >
                  <div>
                    <p className="preview-main-stat">
                      <strong>{selectedMainStat.label}</strong>
                      <span>{selectedMainStat.lvl_value}</span>{" "}
                    </p>
                    <br />
                    <p className="preview-sub-stat">
                      {selectedOption2.label}
                      <span>{displayValueSubAffix2}</span>{" "}
                    </p>
                    <p className="preview-sub-stat">
                      {selectedOption3.label}
                      <span>{displayValueSubAffix3}</span>{" "}
                    </p>
                    <p className="preview-sub-stat">
                      {selectedOption4.label}
                      <span>{displayValueSubAffix4}</span>{" "}
                    </p>
                    <p className="preview-sub-stat">
                      {selectedOption5.label}
                      <span>{displayValueSubAffix5}</span>{" "}
                    </p>
                  </div>
                </Modal>
              )}
          </div>
          <div className="col-sm-5">
            {selectedCommand === "Relics" &&
              selectedPiece &&
              selectedMainStat &&
              selectedOption2 &&
              selectedOption3 &&
              selectedOption4 &&
              selectedOption5 && (
                <Button type="primary" onClick={showModal}>
                  Preview
                </Button>
              )}
          </div>
        </div>
        <div style={{ marginTop: "50px" }} />
      </div>
    </>
  );
}

export default HandleRelics;
