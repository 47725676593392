import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import monster_en from "../data/en/Battle Stages.json";
import monster_vi from "../data/vi/Battle Stages.json";
import monsterVisual_en from "../data/en/NPC Monsters (Spawnable).json";
import monsterVisual_vi from "../data/vi/NPC Monsters (Spawnable).json";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";

function HandleSpawn() {
  const [selectedMonsterOption, setSelectedMonsterOption] = useState([]);
  const [selectedMonsterVisualOption, setSelectedMonsterVisualOption] =
    useState([]);
  const [level, setLevel] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const monsterData = currentLanguage === "vi" ? monster_vi : monster_en;
  const monsterVisualData =
    currentLanguage === "vi" ? monsterVisual_vi : monsterVisual_en;

  const handleSelectMonsterChange = (selectedMonsterOption) => {
    setSelectedMonsterOption(selectedMonsterOption);
  };

  const handleSelectMonsterVisualChange = (selectedMonsterVisualOption) => {
    setSelectedMonsterVisualOption(selectedMonsterVisualOption);
  };

  const handleLevelChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    } else if (newValue > 95) {
      newValue = 95;
    }
    setLevel(newValue);
  };


  const handleQuantityChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    }
    setQuantity(newValue);
  };

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("spawn")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("enemy-name")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedMonsterOption}
              onChange={handleSelectMonsterChange}
              options={monsterData.map((monster) => ({
                value: monster.id,
                label: monster.name,
              }))}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("enemy-visual")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedMonsterVisualOption}
              onChange={handleSelectMonsterVisualChange}
              options={monsterVisualData.map((monsterVisual) => ({
                value: monsterVisual.id,
                label: monsterVisual.name,
              }))}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("level")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={level}
              onChange={(e) => handleLevelChange(e.target.value)}
              min={1}
              max={95}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("quantity")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={quantity}
              onChange={(e) => handleQuantityChange(e.target.value)}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            {selectedMonsterOption && selectedMonsterVisualOption && (
              <input
                type="text"
                value={`/spawn ${selectedMonsterVisualOption.value} s${selectedMonsterOption.value} lv${level} x${quantity}`}
                readOnly
                ref={inputRef3}
                className="command"
                onClick={handleInputClick3}
              />
            )}
          </div>
          <div className="col-sm-5" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            <h3 className="description">{t("note")}</h3>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: t("note-detail") }}
            />
          </div>
          <div className="col-sm-5" />
        </div>
      </div>
      {showNotification && (
        <div className="notification">{t("copySuccess")}</div>
      )}
      <div style={{ marginTop: "100px" }} />
    </>
  );
}

export default HandleSpawn;
