import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./BaseComponent.css";
import lcversion from "../data/version.txt";
import HandleRelics from "../Handle/HandleRelics";

function Relics() {
  const { t } = useTranslation();
  const [version, setVersion] = useState("");

  useEffect(() => {
    // Đọc nội dung từ tệp version.txt khi component được tạo ra
    fetch(lcversion)
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error("Error fetching version:", error));
  }, []);

  return (
    <div>
      <p className="main-version">
        {t("versionText")}: {version}
      </p>
        <HandleRelics/>
    </div>
  );
}

export default Relics;
