// src/App.js
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Cmdbeginner from "./component/Cmdbeginner";
import { useEffect } from "react";

import "./App.css";

function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 900);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // State để theo dõi component hiện tại
  const [currentComponent, setCurrentComponent] = useState(<Cmdbeginner />);

  // Hàm để thay đổi component
  const changeComponent = (component) => {
    setCurrentComponent(component);
  };

  return (
    <Row className="overview-container">
      <Col md={isDesktop ? 3 : 0}>
        <Sidebar changeComponent={changeComponent} />
      </Col>
      <Col md={isDesktop ? 9 : 12}>
        {currentComponent} {/* Hiển thị component hiện tại */}
      </Col>
    </Row>
  );
}

export default App;
