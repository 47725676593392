import "bootstrap/dist/css/bootstrap.min.css";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";

function HandlePerfectRelics() {
  const { t} = useTranslation();

  return (
    <div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("perfectrelics")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("outdated")}</h5>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-7">
            <div
              className="outdated-message"
              dangerouslySetInnerHTML={{ __html: t("outdated-message") }}
            />
          </div>
          <div className="col-sm-4" />
        </div>
      </div>
      <div style={{ marginTop: "100px" }} />
    </div>
  );
}

export default HandlePerfectRelics;
