import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import lightcones_en from "../data/en/Lightcones.json";
import lightcones_vi from "../data/vi/Lightcones.json";
import "./BaseHandle.css";
import { useTranslation } from "react-i18next";

function HandleLightcones() {
  const [selectedOption, setSelectedOption] = useState([]);
  const [level, setLevel] = useState(1);
  const [rank, setRank] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const lightconesData = currentLanguage === 'vi' ? lightcones_vi : lightcones_en;

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  
  const handleLevelChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    } else if (newValue > 80) {
      newValue = 80;
    }
    setLevel(newValue);
  };

  const handleRankChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    } else if (newValue > 5) {
      newValue = 5;
    }
    setRank(newValue);
  };

  const handleQuantityChange = (value) => {
    let newValue = parseInt(value);
    if (isNaN(newValue) || newValue < 1) {
      newValue = 1;
    }
    setQuantity(newValue);
  };

  const inputRef3 = useRef(null);
  const handleInputClick3 = () => {
    if (inputRef3.current) {
      inputRef3.current.select();
      document.execCommand("copy");
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 2000); // Hide notification after 2 seconds
    }
  };

  const handleFocusAndClick = (e) => {
    e.target.select();
  };

  return (
    <>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <h2 className="title">{t("lightcones")}</h2>
          </div>
          <div className="col-sm-8" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("lightcones-name")}</h5>
          </div>
          <div className="col-sm-6">
            <Select
              className="select"
              value={selectedOption}
              onChange={handleSelectChange}
              options={lightconesData.map((lightcones) => ({
                value: lightcones.id,
                label: lightcones.name,
              }))}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("level")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={level}
              onChange={(e) => handleLevelChange(e.target.value)}
              min={1}
              max={80}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("lightcones-rank")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={rank}
              onChange={(e) => handleRankChange(e.target.value)}
              min={1}
              max={5}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h5 className="name-of-selection">{t("quantity")}</h5>
          </div>
          <div className="col-sm-6">
            <input
              type="number"
              value={quantity}
              onChange={(e) => handleQuantityChange(e.target.value)}
              className="LevelInput"
              onClick={handleFocusAndClick}
              onFocus={handleFocusAndClick}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
      <br />
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-2">
            <h2 className="final-command">{t("command")}</h2>
          </div>
          <div className="col-sm-9" />
        </div>
      </div>
      <div className="body">
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-6">
            {selectedOption && (
              <input
                type="text"
                value={`/give ${selectedOption.value} lv${level} r${rank} x${quantity}`}
                readOnly
                ref={inputRef3}
                className="command"
                onClick={handleInputClick3}
                onFocus={handleFocusAndClick}
              />
            )}
          </div>
          <div className="col-sm-5" />
        </div>
      </div>
      {showNotification && (
        <div className="notification">{t("copySuccess")}</div>
      )}
      <div style={{marginTop: '100px'}}/>
    </>
  );
}

export default HandleLightcones;
