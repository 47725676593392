// src/i18n/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import các file dịch ngôn ngữ
import translationEN from './locales/TextMapEN.json';
import translationVI from './locales/TextMapVI.json';

// Cấu hình i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    vi: {
      translation: translationVI,
    },
  },
  lng: 'en', // Ngôn ngữ mặc định
  fallbackLng: 'en', // Ngôn ngữ dự phòng
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
